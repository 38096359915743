/* Reset some default browser styles */
body,
h1,
h2,
h3,
p,
ul,
li,
button {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif; /* You can use any font family you prefer */
}

body {
  background-color: #f5f5f5; /* Light gray background for contrast */
  color: #333; /* Deep gray text color */
}

.container {
  max-width: 1200px; /* Limit the width for better readability */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff; /* White background for main content */
  border: 1px solid #e0e0e0; /* Subtle border */
}

/* Consistent styling for headings */
h1,
h2,
h3 {
  color: #333;
  margin-bottom: 16px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

/* Consistent styling for paragraphs */
p {
  color: #333;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

/* Styling for buttons */
button {
  background-color: #333;
  color: #ffffff; /* White text on deep gray background */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #555; /* Slightly lighter gray on hover */
}

/* Styling for lists */
ul {
  list-style-type: none;
  margin-bottom: 16px;
}

li {
  margin-bottom: 8px;
  color: #333;
}

/* If you have any specific class names for your components, you can extend the styles further. */
